import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import View from './pages/View';
import Advertise from './pages/Advertise';
import Withus from './pages/Withus';
import Policy from './pages/Policy';
import User from './pages/User';
import Dashboard from './pages/Dashboard';
import PostView from './pages/PostView';
import FoodArticle from './pages/FoodArticle';
import Videos from './pages/Videos';
import StoriesList from './pages/StoriesList';
import CityGuides from './pages/CityGuides';
import Entertainment from './pages/Entertainment';
import LifeStyle from './pages/LifeStyle';
import Travel from './pages/Travel';
import Food from './pages/Food';
import Money from './pages/Money';
import Health from './pages/Health';
import Technology from './pages/Technology';
import Verify from './pages/Verify';

// Dynamic route handlers grouped by feature
const routes = [
  { path: '/', component: <Home />, exact: true },
  { path: '/about', component: <About /> },
  { path: '/contact', component: <Contact /> },
  { path: '/advertise', component: <Advertise /> },
  { path: '/work-with-us', component: <Withus /> },
  { path: '/privacy-policy', component: <Policy /> },
  { path: '/new-user', component: <User /> },
  { path: '/dashboard', component: <Dashboard /> },
  { path: '/today-news', component: <View /> },
  
  // Post and articles related routes
  { path: '/:posturl', component: <PostView />, exact: true },
  { path: '/food-article', component: <FoodArticle /> },
  
  // Video routes
  { path: '/videos', component: <Videos />, exact: true },
  { path: '/videos/:selectsubcategory', component: <Videos /> },
  
  // Web Stories
  { path: '/webstories', component: <StoriesList /> },
  
  // City Guides routes
  { path: '/city-guides', component: <CityGuides />, exact: true },
  { path: '/city-guides/:selectcityid', component: <CityGuides /> },
  { path: '/city-guides/:selectcityid/:selectsubcategory', component: <CityGuides /> },
  
  // Entertainment routes
  { path: '/entertainment', component: <Entertainment />, exact: true },
  { path: '/entertainment/:selectsubcategory', component: <Entertainment /> },
  
  // Lifestyle routes
  { path: '/life-style', component: <LifeStyle />, exact: true },
  { path: '/life-style/:selectsubcategory', component: <LifeStyle /> },
  
  // Travel routes
  { path: '/travel', component: <Travel />, exact: true },
  { path: '/travel/:selectsubcategory', component: <Travel /> },
  
  // Food routes
  { path: '/food', component: <Food />, exact: true },
  { path: '/food/:selectsubcategory', component: <Food /> },
  
  // Other static pages
  { path: '/money', component: <Money /> },
  { path: '/health', component: <Health /> },
  { path: '/technology', component: <Technology /> },

  // Verification route
  { path: '/verify/:token/:mail', component: <Verify /> },
];

export default routes;
