import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import "../css/View.css";
import "../App.css";
import "../css/animations.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';
import NavBar from '../Common/Header';
import Dropdown from 'react-bootstrap/Dropdown';
import Footer from '../Common/Footer';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faUser, faBookmark} from "@fortawesome/free-regular-svg-icons";
import { faFacebookF, faXTwitter, faLinkedinIn, faInstagram} from "@fortawesome/free-brands-svg-icons";
import { faShare, faCheck, faBookmark as solidBookmark } from "@fortawesome/free-solid-svg-icons";
import small_ad_image from "../images/small_ads.png";
import movie_img from "../images/small-size-images/movies.png";
import korean_img from "../images/small-size-images/koreya.png";
import tv_series from "../images/small-size-images/tech.png";
import lasttest from "../images/small-size-images/videos.png";
import sendicon from "../images/sendicon.png";
import large_ads from "../images/large-ads.png";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import ProgressBar from '../Components/ProgressBar';
import Button from 'react-bootstrap/Button';
import Iserve from '../images/recipes/img_iserve.svg';
import Iprinter from '../images/recipes/img_i_printer.svg';
import Istar from '../images/recipes/img_istar.svg';
import Itime from '../images/recipes/img_itime.svg';
import Table from 'react-bootstrap/Table';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton } from 'react-share';
import "../css/ckeditor5.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Carousel from "react-multi-carousel";
import MetaTags from '../Common/MetaTags';
function PostView()
{
    const main_website_url = 'http://localhost:3000/';
    const printRef = useRef();
    let { posturl } = useParams();
    const [pagecontent, setPageContent] = useState('');
    const [page_post_id, setPagePostId] = useState(0);
    const [navlist, setNavlist] = useState(null);
    const [pagefooter, setPageFooter] = useState(null);
    const [author_img, setAuthorImg] = useState(""); 
    const [author_name, setAuthorName] = useState("");
    const [post_followers, setFollowers] = useState("0");
    const [post_views, setViews] = useState("0");
    const [auFacebook, setAuFacebook] = useState("");
    const [auIndeed, SetauIndeed] = useState("");
    const [auTwitter, setauTwitter] = useState("");
    const [auInstagram, setauInstagram] = useState("");
    const [trending_list, setTranding] = useState("");
    const [todaynews_list, setTodayNews] = useState("");
    const [posttitle, setPostTitle] = useState("");
    const [postimage, setPostImage] = useState("");
    const [youtubevideo, setYoutubeVideo] = useState("");
    const [the_reviews, setTheReviews] = useState("");
    const [review_breakdown, setReviewBreakdown] = useState("");
    const [recipes, setRecipes] = useState("");
    const [pros_cons, setProsCons] = useState("");
    const [product_reference, setProductReference] = useState("");
    const [postcategory, setPostCategory] = useState("");
    const [postsubcategory, setPostSubCategory] = useState("");
    const [postcity, setPostCity] = useState("");
    const [postinnercategory, setPostinnerCategory] = useState("");
    const [showShareOptions, setShowShareOptions] = useState(false);
    const [similararticles, setSimilarArticles] = useState("");
    const [postcategoryurl, setPostCategoryurl] = useState("");
    const [postsubcategoryurl, setPostUubCategoryUrl] = useState("");
    const [postcityurl, setPostCityUrl] = useState("");
    const [postinnercategoryurl, setPostinnerCategoryUrl] = useState("");
    const [userpostcomment, setUserPostComment] = useState("");
    const [postsavestatus, setPostSaveStatus] = useState(0);
    const [postcomments, setPostComments] = useState("");
    const [related_posts, setRelatedPosts] = useState("");
    const [post_added_on, setPostAddedOn] = useState("");
    const [current_post_category_id, setCurrentPostCategoryid] = useState(0);
    const [loaderstate, setLoader] = useState(true);
    const [pagemetatags, setPageMetaTags] = useState([]);
    const currentUrl = window.location.href;
    const onclickOpenurl = (pageurl) => {
        window.location = pageurl;
    }
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 6
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    const handleWriteComment = (event) => {
        setUserPostComment(event.target.value);
    };

    const ShareDropdown = ({ url }) => (
        <Dropdown className='iconmenu'>
          <Dropdown.Toggle as="span" className="share-dropdown-icon">
            <FontAwesomeIcon icon={faShare} style={{ cursor: "pointer", fontSize: "25px" }} />
          </Dropdown.Toggle>
      
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => { navigator.clipboard.writeText(url); alert('URL copied to clipboard!'); }}>
              Copy URL
            </Dropdown.Item>
            <Dropdown.Item as="div">
              <FacebookShareButton url={url}>
                Facebook
              </FacebookShareButton>
            </Dropdown.Item>
            <Dropdown.Item as="div">
              <TwitterShareButton url={url}>
                Twitter
              </TwitterShareButton>
            </Dropdown.Item>
            <Dropdown.Item as="div">
              <LinkedinShareButton url={url}>
                LinkedIn
              </LinkedinShareButton>
            </Dropdown.Item>
            <Dropdown.Item as="div">
              <WhatsappShareButton url={url}>
                WhatsApp
              </WhatsappShareButton>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
      
      const Badges = ({ url }) => (
        <Row style={{marginTop: "20px", marginTop: "-15px" }}>
          <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12} className="mb-3">
            <Stack direction="horizontal" gap={2}>
                <div className=""><span style={{fontSize: "15px", opacity: "0.7", color: "#5a5f5f"}}>Posted On: {post_added_on}</span></div>
                <div className="p-2 ms-auto"><ShareDropdown url={url} style={{padding: "7px 20px 7px 20px"}} /></div>
                <div className="p-2"><span>{postsavestatus == "1" && 
                                <FontAwesomeIcon icon={solidBookmark} style={{ cursor: "pointer", fontSize: "25px" }} />
                        }
                        {postsavestatus == "0" && 
                            <FontAwesomeIcon icon={faBookmark} onClick={savePost} style={{ cursor: "pointer", fontSize: "25px" }} />
                        }</span></div>
            </Stack>
               
          </Col>
        </Row>
      );

    const insertBadgesAfterH1 = (content) => {
        const h1EndTag = "</h1>";
        const index = content.indexOf(h1EndTag);
        if (index === -1) return content; // No h1 tag found
        const beforeH1 = content.substring(0, index + h1EndTag.length);
        const afterH1 = content.substring(index + h1EndTag.length);
        return beforeH1 + `<div id="badges-container"></div>` + afterH1;
      };
      

    const handlePrint = () => {
        const printContent = printRef.current;
        const printWindow = window.open('', '', 'width=800,height=600');
    
        // Get all CSS styles from the current page
        const styles = Array.from(document.styleSheets)
          .map((styleSheet) => {
            try {
              return Array.from(styleSheet.cssRules)
                .map((rule) => rule.cssText)
                .join('');
            } catch (e) {
              console.error(e);
              return '';
            }
          })
          .join('');
    
        printWindow.document.write(`
          <html>
            <head>
              <title>Print Recipe</title>
              <style>${styles}</style>
            </head>
            <body>
              ${printContent.innerHTML}
            </body>
          </html>
        `);
    
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
      };
    const handleBuy = (link) => {
        window.open(link, '_blank');
    };
    const handleInnerPages = (link) => {
        window.open(link);
    };
    const applyImageStyles = (htmlString) => {
        // Create a temporary element to manipulate HTML
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;

        // Apply styles to <img> tags
        const imgElements = tempElement.querySelectorAll('figure img');
        imgElements.forEach(img => {
            img.style.width = '100%';
            img.style.height = 'auto';
        });

        return tempElement.innerHTML;
    };
    const removeInnerHtmlByClass = (htmlString, className) => {
        // Create a temporary element to manipulate HTML
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;

        // Find elements with the specified class and remove their inner HTML
        const elementsToRemove = tempElement.querySelectorAll('.' + className);
        elementsToRemove.forEach(element => {
            element.innerHTML = ''; // Remove inner HTML content
        });
        return tempElement.innerHTML;
    };
    const commentPost = async () => {
        try {
                const session_bdo_id = sessionStorage.getItem('bdo_id');
                if (session_bdo_id !== "" && session_bdo_id !== undefined && !isNaN(session_bdo_id) && Number(session_bdo_id) > 0) {
                    var response = await axios.post('https://broadwayoriginals.com/apis/index.php', {postcomment: userpostcomment, post_id: page_post_id, user_id: session_bdo_id});
                    if(response.data.status == "1")
                    {
                        setPostComments(response.data.data);
                    }
                }else{
                    toast.error("Please log in to comment on this post.");
                }
                
          } catch (error) {
            
          }
    };
    const savePost = async () => {
        try {
                const session_bdo_id = sessionStorage.getItem('bdo_id');
                if (session_bdo_id !== "" && session_bdo_id !== undefined && !isNaN(session_bdo_id) && Number(session_bdo_id) > 0) {
                    var response = await axios.post('https://broadwayoriginals.com/apis/index.php', {savepost: "", post_id: page_post_id, user_id: session_bdo_id, category_id: current_post_category_id});
                    var response_data = JSON.parse(response.data);
                    if(response_data.status == "1")
                    {
                        setPostSaveStatus(1);
                    }else{
                        toast.error(response_data.msg);
                    }
                }else{
                    toast.error("Please log in to save this post.");
                }
                
          } catch (error) {
            
          }
    };
    const fetchPageData = async (posturl) => {
        try {
          var session_bdo_id = sessionStorage.getItem('bdo_id');
          if (session_bdo_id !== "" && session_bdo_id !== undefined && !isNaN(session_bdo_id) && Number(session_bdo_id) > 0) {
          }else{
            session_bdo_id = 0;
          }
          
          const response = await axios.get('https://broadwayoriginals.com/apis/index.php?post_url='+posturl+'&u='+session_bdo_id);
          setLoader(false);
          setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
          setPageMetaTags(response.data.pagedata.meta_data);
          let modifiedContent = response.data.pagedata.post_content;
          setPageContent(insertBadgesAfterH1(modifiedContent));
          setPostTitle(response.data.pagedata.post_title);
          document.title = "Broadway Originals | Post View | "+response.data.pagedata.post_title;
          setPagePostId(response.data.pagedata.post_id);
          setPostAddedOn(response.data.pagedata.post_added_on);
          if(response.data.pagedata.author_img !=""){
            setAuthorImg("https://broadwayoriginals.com/"+response.data.pagedata.author_img);
            setAuthorName(response.data.pagedata.author_name);
          }
          if(response.data.pagedata.video_link !=""){
            setPostImage('https://broadwayoriginals.com/'+response.data.pagedata.post_image);
            setYoutubeVideo(response.data.pagedata.video_link);
          }
          if(response.data.pagedata.post_followers == ""){
            setFollowers("0");
          }else{
            setFollowers(response.data.pagedata.post_followers);
          }
          if(response.data.pagedata.post_views == ""){
            setViews("0");
          }else{
            setViews(response.data.pagedata.post_views);
          } 
          if(response.data.pagedata.saved_status == "1"){
            setPostSaveStatus(1);
          }else{
            setPostSaveStatus(0);
          }
           
          setAuFacebook(response.data.pagedata.facebook);
          SetauIndeed(response.data.pagedata.indeed);
          setauTwitter(response.data.pagedata.twitter);
          setauInstagram(response.data.pagedata.instagram);
          setTranding(response.data.trending);
          setTodayNews(response.data.todaynews); 
          setPostComments(response.data.pagedata.post_comments); 
          setRelatedPosts(response.data.pagedata.related_posts);
          setTheReviews(response.data.pagedata.the_reviews);
          setReviewBreakdown(response.data.pagedata.review_breakdown); 
          setRecipes(response.data.pagedata.recipes); 
          setProductReference(response.data.pagedata.product_reference); 
          setProsCons(response.data.pagedata.pros_cons);   
          setPostCategory(response.data.pagedata.category);
          setCurrentPostCategoryid(response.data.pagedata.category.id);
          setPostSubCategory(response.data.pagedata.sub_category); 
          setPostCity(response.data.pagedata.city_id);
          setPostinnerCategory(response.data.pagedata.inner_category); 
          setSimilarArticles(response.data.pagedata.similar_post_url); 
          setPostCategoryurl(response.data.pagedata.post_category_url); 
          setPostCityUrl(response.data.pagedata.post_city_url);
          setPostUubCategoryUrl(response.data.pagedata.post_sub_category_url); 
          setPostinnerCategoryUrl(response.data.pagedata.post_inner_category_url);     
          setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
          //setYoutubeVideo();
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        setLoader(true);
        fetchPageData(posturl);

    }, []);
   
    useEffect(() => {
        const badgesContainer = document.getElementById("badges-container");
        if (badgesContainer) {
          ReactDOM.render(<Badges onShareClick={() => {
            setShowShareOptions(!showShareOptions);
            navigator.clipboard.writeText(window.location.href);
            alert('URL copied to clipboard!');
          }} url={window.location.href} />, badgesContainer);
        }
    }, [pagecontent]);
      

    return(
        <>
            <Container fluid className='top-header'>
            {loaderstate === true && 
                <Row >
                    <Col >
                        <div className="preloader">
                            <div className="preloader-animation">
                                <div className="dot dot-1"></div>
                                <div className="dot dot-2"></div>
                                <div className="dot dot-3"></div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                <filter id="goo">
                                    <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"></feGaussianBlur>
                                    <feColorMatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"></feColorMatrix>
                                </filter>
                                </defs>
                            </svg>
                        </div>
                    </Col>
                </Row>}
                <MetaTags tags={pagemetatags} />
                {navlist} 
                <ToastContainer />
            </Container>
            <Container className='mt-5'>
                <Row>
                    <Col className="view-filter-header mb-3">
                        <div className='desktop-view-stack'>
                            <Stack direction="horizontal" gap={2}>
                                <Badge bg="light" text="dark" className="city-btn" style={{borderRadius: "27px", paddingRight: "12px", paddingLeft: "12px", paddingTop: "6px", paddingBottom: "6px", fontFamily: "Roboto", fontSize: "14px", fontWeight: "400", color: "#131313"}} onClick={() => handleInnerPages(similararticles)}>Similar Articles</Badge>  
                                {postcategory.name!="" && postcategory.name!="[]" && postcategory.name!="undefined" && 
                                    <Badge bg="light" text="dark" className="city-btn" style={{borderRadius: "27px", paddingRight: "12px", paddingLeft: "12px", paddingTop: "6px", paddingBottom: "6px", fontFamily: "Roboto", fontSize: "14px", fontWeight: "400", color: "#131313"}} onClick={() => handleInnerPages(postcategoryurl)}>{postcategory.name}</Badge>
                                }
                                {postcity.name!="" && postcity.name!="[]" && postcity.name!="undefined" && 
                                    <Badge bg="light" text="dark" className="city-btn" style={{borderRadius: "27px", paddingRight: "12px", paddingLeft: "12px", paddingTop: "6px", paddingBottom: "6px", fontFamily: "Roboto", fontSize: "14px", fontWeight: "400", color: "#131313"}} onClick={() => handleInnerPages(postcityurl)}>{postcity.name}</Badge>
                                }
                                {postsubcategory.name!="" && postsubcategory.name!="[]" && postsubcategory.name!="undefined" && 
                                    <Badge bg="light" text="dark" className="city-btn" style={{borderRadius: "27px", paddingRight: "12px", paddingLeft: "12px", paddingTop: "6px", paddingBottom: "6px", fontFamily: "Roboto", fontSize: "14px", fontWeight: "400", color: "#131313"}} onClick={() => handleInnerPages(postsubcategoryurl)}>{postsubcategory.name}</Badge>
                                }
                                {postinnercategory.name!="" && postinnercategory.name!="[]" && postinnercategory.name!="undefined" && 
                                    <Badge bg="light" text="dark" className="city-btn" style={{borderRadius: "27px", paddingRight: "12px", paddingLeft: "12px", paddingTop: "6px", paddingBottom: "6px", fontFamily: "Roboto", fontSize: "14px", fontWeight: "400", color: "#131313"}} onClick={() => handleInnerPages(postinnercategoryurl)}>{postinnercategory.name}</Badge>
                                }
                            </Stack>
                        </div>
                        <div className='mobile-view-stack'>
                                    <Badge bg="light" text="dark" className="city-btn" style={{borderRadius: "27px", paddingRight: "12px", paddingLeft: "12px", paddingTop: "6px", paddingBottom: "6px", fontFamily: "Roboto", fontSize: "14px", fontWeight: "400", color: "#131313"}} onClick={() => handleInnerPages(similararticles)}>Similar Articles</Badge>  
                                    {postcategory.name!="" && postcategory.name!="[]" && postcategory.name!="undefined" && 
                                        <Badge bg="light" text="dark" className="city-btn" style={{borderRadius: "27px", paddingRight: "12px", paddingLeft: "12px", paddingTop: "6px", paddingBottom: "6px", fontFamily: "Roboto", fontSize: "14px", fontWeight: "400", color: "#131313"}} onClick={() => handleInnerPages(postcategoryurl)}>{postcategory.name}</Badge>
                                    }
                                    {postcity.name!="" && postcity.name!="[]" && postcity.name!="undefined" && 
                                        <Badge bg="light" text="dark" className="city-btn" style={{borderRadius: "27px", paddingRight: "12px", paddingLeft: "12px", paddingTop: "6px", paddingBottom: "6px", fontFamily: "Roboto", fontSize: "14px", fontWeight: "400", color: "#131313"}} onClick={() => handleInnerPages(postcityurl)}>{postcity.name}</Badge>
                                    }
                                    {postsubcategory.name!="" && postsubcategory.name!="[]" && postsubcategory.name!="undefined" && 
                                        <Badge bg="light" text="dark" className="city-btn" style={{borderRadius: "27px", paddingRight: "12px", paddingLeft: "12px", paddingTop: "6px", paddingBottom: "6px", fontFamily: "Roboto", fontSize: "14px", fontWeight: "400", color: "#131313"}} onClick={() => handleInnerPages(postsubcategoryurl)}>{postsubcategory.name}</Badge>
                                    }
                                    {postinnercategory.name!="" && postinnercategory.name!="[]" && postinnercategory.name!="undefined" && 
                                        <Badge bg="light" text="dark" className="city-btn" style={{borderRadius: "27px", paddingRight: "12px", paddingLeft: "12px", paddingTop: "6px", paddingBottom: "6px", fontFamily: "Roboto", fontSize: "14px", fontWeight: "400", color: "#131313"}} onClick={() => handleInnerPages(postinnercategoryurl)}>{postinnercategory.name}</Badge>
                                    }
                        </div>
                        
                    </Col>
                </Row>
                <Row className="mb-3 mt-3 ">
                    <Col className='col-md-9 col-lg-9 col-sm-12' >
                       
                        {youtubevideo !="" && 
                            <div className='mb-3'><iframe
                            width="100%"
                            height="350"
                            src={youtubevideo}
                            title={posttitle}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen
                            style={{border: "2px solid rgba(0, 0, 0, .5)"}}
                            ></iframe></div>
                        }
                            
                        <div className="ck-blurred ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline">
                            <div dangerouslySetInnerHTML={{ __html: applyImageStyles(pagecontent) }} style={{fontSize: "1.25rem", lineHeight: "1.75rem", color: 'rgb(19, 19, 19)', fontFamily: 'Roboto'}}></div>
                        </div>
                        <Row>
                            <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12} className='mt-5    '>
                                <InputGroup className='mb-3'>
                                    <Form.Control as="textarea" aria-label="Comment" placeholder='Comment' value={userpostcomment} onChange={handleWriteComment} />
                                    <InputGroup.Text><Image src={sendicon} className='post-send-btn' onClick={commentPost} /></InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                            <Card  style={{border: "1px solid #E5E5E5", background: "none", borderRadius: "10px", borderRadius: "16px"}}>
                                
                                    <Card.Body className='mb-2'>
                                        <Card.Title style={{fontFamily: "Roboto", fontSize: "17px", fontWeight: "bold", lineHeight: "40px", textAlign: "left", color: "#131313"}}>Comments</Card.Title>
                                        <Card.Text>
                                        {postcomments!="" && postcomments!="[]" && postcomments!="null" && 
                                            <>
                                                {postcomments !== "" && postcomments.map((commentdetails, index) => (
                                                     <Card style={{background: "none"}} className='mb-3'>
                                                         <Card.Body style={{padding: "10px"}}>{commentdetails.comment}</Card.Body>
                                                     </Card>
                                                ))}
                                            </>
                                           
                                        }
                                        </Card.Text>
                                    </Card.Body>
                                    
                                </Card>
                            </Col>
                        </Row>
                        {/* Print Recipe */}
                        <Row id="print_recipe_block" ref={printRef}>
                            <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                               {recipes!="" && recipes!="[]" && recipes!="null" &&
                                    <Card className='mt-5' style={{border: "1px solid rgb(229, 229, 229)", background: "none", borderRadius: "10px", padding: "1rem", borderRadius: "16px"}}>
                                    {recipes!="" && recipes.map((recipe) =>
                                    <Card.Body className='mb-2'>
                                        <Card.Title style={{fontFamily: "Roboto", fontSize: "20px", fontWeight: "700", lineHeight: "40px", textAlign: "left", color: "#131313"}}>{recipe.recipe_name}</Card.Title>
                                        <Card.Text>
                                            <Row >
                                                <Col xxxl={2} xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} xxs={2} style={{display: "flex", alignItems: "center"}}>
                                                    <span style={{color: "#848484", fontFamily: "Roboto", fontSize: "15px", fontWeight: "400", lineheight: "24px", textAlign: "left"}}><Image src={Iserve} /> Serves: {recipe.serves}</span>
                                                </Col>
                                                <Col xxxl={3} xxl={3} xl={3} lg={3} md={3} sm={3} xs={3} xxs={3} style={{display: "flex", alignItems: "center"}}>
                                                    <span style={{color: "#848484", fontFamily: "Roboto", fontSize: "15px", fontWeight: "400", lineheight: "24px", textAlign: "left"}}><Image src={Itime} /> Cooking time: {recipe.cooking_time}</span>
                                                </Col>
                                                <Col xxxl={2} xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} xxs={2} style={{display: "flex", alignItems: "center"}}>
                                                    <span style={{color: "#848484", fontFamily: "Roboto", fontSize: "15px", fontWeight: "400", lineheight: "24px", textAlign: "left"}}><Image src={Istar} /> Effort: {recipe.effort}</span>
                                                </Col>
                                                <Col xxxl={2} xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} xxs={2}>
                                                     
                                                </Col>
                                                <Col xxxl={3} xxl={3} xl={3} lg={3} md={3} sm={3} xs={3} xxs={3} style={{textAlign: "end"}}>
                                                    <Badge bg="light"  style={{border: "1px solid #848484", color: "#848484", fontFamily: "Roboto", cursor: "pointer"}}  onClick={handlePrint}><Image src={Iprinter} style={{width: "18%", marginRight: "5px"}} /> Print Recipe</Badge>
                                                </Col>
                                            </Row>  

                                            <Row className='mt-2'>
                                                <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12} >
                                                    <Table striped bordered style={{border: "1px solid #E5E5E5", borderRadius: "18px"}}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{paddingLeft: "25px", border: "1px solid #E5E5E5", background: "none", olor: "#131313", fontFamily: "Roboto", fontSize: "20px", fontWeight: "bold", lineheight: "24px"}}>Ingredients</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {recipe.ingredients !== "" && recipe.ingredients.map((ingredient, index) => (
                                                                <tr key={index}>
                                                                    <td style={{
                                                                        color: "#131313", fontFamily: "Roboto", fontSize: "18px", fontWeight: "400", lineheight: "24px",
                                                                        paddingLeft: "25px",
                                                                        border: "1px solid #E5E5E5",
                                                                        background: index % 2 === 0 ? "#E5E5E5" : "none"
                                                                    }}>
                                                                        {ingredient.ingredient}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </Card.Text>
                                    </Card.Body>
                                    )}
                                    </Card>
                               }
                            </Col>
                        </Row>
                        {/* The Review */}
                        <Row>
                            <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                            {the_reviews !="" && 
                                <Card className='mt-5' style={{border: "2px solid rgb(242, 242, 242)", background: "rgb(239 239 239)", borderRadius: "10px", padding: "1rem", borderRadius: "16px"}}>
                                    <Card.Header style={{fontFamily: "Roboto", fontSize: "19px", fontWeight: "bold", lineHeight: "32px", textAlign: "left", color: "hsl(0, 0%, 4%)", background: "none", borderBottom: "0px", paddingBottom: "0px"}} className='pt-3'>The Review</Card.Header>
                                    {the_reviews!="" && the_reviews.map((review) =>
                                    <Card.Body className='mb-2'>
                                        <Card.Title style={{fontFamily: "Roboto", fontSize: "22px", fontWeight: "700", lineHeight: "40px", textAlign: "left", color: "#131313"}}>{review.review_title}</Card.Title>
                                        <Card.Text>
                                            <Row>
                                                <Col xxxl={3} xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} xxs={12}>
                                                    <div style={{background: "#00A8AA",border: "1px solid #00A8AA", borderRadius: "10px",height: "150px", width: "85%"}}>
                                                        <div style={{color: "white", textAlign: "center", fontSize: "45px", paddingTop: "20px", fontFamily: "Roboto"}}>{review.review_percentage} %</div>
                                                        <div style={{color: "white", textAlign: "center", paddingTop: "5px", fontSize: "19px", fontFamily: "Roboto"}}>Score</div>
                                                    </div>
                                                </Col>
                                                <Col xxxl={9} xxl={9} xl={9} lg={9} md={9} sm={12} xs={12} xxs={12} style={{fontFamily: "Roboto", fontSize: "1.25rem", lineHeight: "1.75rem", fontWeight: "400"}}>{review.description}</Col>
                                            </Row>
                                        </Card.Text>
                                    </Card.Body>
                                    )}
                                </Card>
                            }
                            </Col>
                        </Row>
                        {/* Pros & Cons */}
                        <Row className='mt-5'>
                            <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                                {pros_cons!="" && 
                                    <Row style={{backgroundColor: "rgb(228 228 228)", margin: "0px", padding: "1rem", borderRadius: "16px"}} className="p-4">
                                        <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12}>
                                            <ListGroup>
                                                <ListGroup.Item style={{background: 'none',border: 'none',color: 'rgb(19, 19, 19)',fontWeight: 700,fontSize: '1.5rem',lineHeight: '2rem',fontFamily: 'Roboto'}}>Pros</ListGroup.Item>
                                                {pros_cons!="" && pros_cons.map((pros) =>
                                                    <>
                                                        {pros.category == "1" &&
                                                            <ListGroup.Item style={{background: "none", border: "none",  display: "flex", alignItems: "center", gap: "0.5rem", color: 'rgb(19, 19, 19)',fontWeight: 400,fontSize: "1.25rem", lineHeight: '2rem',fontFamily: 'Roboto'}}>
                                                                <div style={{width: "10%", textAlign: "center"}}>
                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                </div>
                                                                <div style={{width: "90%"}}>
                                                                    {pros.description}
                                                                </div>
                                                            </ListGroup.Item>
                                                        }
                                                    </>
                                                )}
                                            </ListGroup>
                                        </Col>
                                        <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12}>
                                        <ListGroup>
                                            <ListGroup.Item style={{background: 'none',border: 'none',color: 'rgb(19, 19, 19)',fontWeight: 700,fontSize: '1.5rem',lineHeight: '2rem',fontFamily: 'Roboto'}}>Cons</ListGroup.Item>
                                                {pros_cons!="" && pros_cons.map((cons) =>
                                                    <>
                                                        {cons.category == "0" &&
                                                            <ListGroup.Item style={{background: "none", border: "none",  display: "flex", alignItems: "center", gap: "0.5rem", color: 'rgb(19, 19, 19)',fontWeight: 400,fontSize: "1.25rem", lineHeight: '2rem',fontFamily: 'Roboto'}}>
                                                                <div style={{width: "10%", textAlign: "center"}}>
                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                </div>
                                                                <div style={{width: "90%"}}>
                                                                    {cons.description}
                                                                </div>
                                                            </ListGroup.Item>
                                                        }
                                                    </>
                                                )}
                                            </ListGroup>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>
                        {/* Review BreakDown */}
                        <Row>
                            <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                            {review_breakdown !="" && 
                                <Card className='mt-5' style={{border: "2px solid rgb(242, 242, 242)", background: "rgb(239 239 239)", borderRadius: "10px", padding: "1rem", borderRadius: "16px"}}>
                                    <Card.Header style={{fontFamily: "Roboto", fontSize: "25px", fontWeight: "bold", lineHeight: "32px", textAlign: "left", color: "hsl(0, 0%, 4%)", background: "none", borderBottom: "0px", paddingBottom: "0px"}} className='pt-3'>Review Breakdown</Card.Header>
                                    {review_breakdown!="" && review_breakdown.map((breakdown) =>
                                    <Card.Body style={{paddingBottom: "0px"}}>
                                        <Card.Title style={{fontFamily: "Roboto", fontSize: "1.1rem", fontWeight: "700", lineHeight: "40px", textAlign: "left", color: "#131313"}}>{breakdown.topic}</Card.Title>
                                        <Card.Text>
                                            <Row>
                                                <Col xxxl={11} xxl={11} xl={11} lg={11} md={11} sm={9} xs={9} xxs={9} style={{alignItems: "center", display: "flex"}}>
                                                    <ProgressBar progress={breakdown.percentage} /> 
                                                </Col>
                                                <Col xxxl={1} xxl={1} xl={1} lg={1} md={1} sm={3} xs={3} xxs={3}>{breakdown.percentage}%</Col>
                                            </Row>
                                        </Card.Text>
                                    </Card.Body>
                                    )}
                                </Card>
                            }
                            </Col>
                        </Row>
                        {/* Blog Product */}
                        {product_reference !="" && 
                        <Row>
                            <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12} className='mb-3'>
                                <Card className='mt-5' style={{border: "1px solid #E5E5E5", background: "none", borderRadius: "10px", padding: "1rem", borderRadius: "16px"}}>
                                    {product_reference!="" && product_reference.map((product) =>
                                    <Card.Body className='mb-2'>
                                        <Card.Title style={{fontFamily: "Roboto", fontSize: "25px", fontWeight: "bold", lineHeight: "40px", textAlign: "left", color: "#131313"}}>{product.product_name}</Card.Title>
                                        <Card.Text>
                                            <Row>
                                                <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12} style={{fontFamily: "Roboto", fontSize: "1.25rem", lineHeight: "1.75rem", fontWeight: "400"}}>{product.description}</Col>
                                            </Row>
                                            {product.references !='' && product.references !='[]' && product.references !='null' && 
                                                <>
                                                    {product.references.map((reference) =>
                                                        <Row className='mt-3' style={{border: "1px solid #E5E5E5", padding: "5px"}}>
                                                            <Col xxxl={8} xxl={8} xl={8} lg={8} md={8} sm={8} xs={8} xxs={8} style={{alignItems: "center", display: "flex"}} className='mb-3'><span><Image src={main_website_url+reference.refer_img} rounded style={{marginRight: "13px"}} /></span> <span style={{color: "#131313", fontFamily: "Roboto", fontSize: "20px", fontWeight: "500", lineHeight: "28px"}}>{reference.refer_site}</span></Col>
                                                            <Col xxxl={2} xxl={2} xl={2} lg={2} md={2} sm={4} xs={4} xxs={4} style={{fontFamily: "Roboto", fontSize: "20px", fontWeight: "700", lineHeight: "28px", textAlign: "left", color: "#131313", alignItems: "center", display: "flex"}} className='mb-3'>{reference.cost}</Col>
                                                            <Col xxxl={2} xxl={2} xl={2} lg={2} md={2} sm={12} xs={12} xxs={12} style={{textAlign: "center"}} className='mb-3'>
                                                                <Button variant="info" style={{background: "#00A8AA", color: "white", borderRadius: "11px", fontSize: "20px", padding: "8px 16px", width: "100%"}} onClick={() => handleBuy(reference.refer_link)} className='m-buy'>Buy Now</Button>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    
                                                </>
                                            }
                                        </Card.Text>
                                    </Card.Body>
                                    )}
                                </Card>
                            
                            </Col>
                        </Row>
                        }
                        {related_posts!="" &&
                            <Row>
                                <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12} className='mb-3 mt-5'>
                                <h4 style={{color: "#00A8AA", marginBottom: "20px"}}><span className="block-header" style={{fontSize: "20px"}}>Related Articles</span></h4>
                                <Carousel responsive={responsive}>
                                    {related_posts.map((relatedpost) =>  
                                            <Card className='h-100 tranding-block-view' >
                                                <Card.Img variant="top" src={"https://broadwayoriginals.com/"+relatedpost.post_img} style={{padding: "20px"}} loading="lazy"  />
                                                <Card.Body>
                                                    <Card.Text className="tranding-post-title" onClick={() => onclickOpenurl("/"+relatedpost.post_custom_url)} style={{cursor: "pointer"}}>{relatedpost.post_title}</Card.Text>
                                                </Card.Body>
                                            </Card>            
                                    )} 
                                </Carousel>
                                
                                </Col>
                            </Row>
                        }
                    </Col>
                    <Col className='col-md-3 col-lg-3 col-sm-12' >
                        {/* Author Block */}
                        {author_img !="" && 
                            <Card style={{border: "1px solid #E5E5E5", background: "rgb(245 245 245)"}} className="text-center mb-3">                              
                                
                                <Card.Body>
                                    <div className='mb-3'>Article by</div>
                                    <Image src={author_img} roundedCircle style={{width: "50%"}} className='mb-3' />
                                    <Card.Title className='mb-3'>{author_name}</Card.Title>
                                    <Card.Text className='mb-3'>
                                        <div className='mb-4'>
                                            <span style={{borderRight: '1px solid #c7c7c7', paddingRight: '20px'}}><FontAwesomeIcon icon={faUser} /> {post_followers}</span>
                                            <span style={{paddingLeft: '20px'}}><FontAwesomeIcon icon={faEye} /> {post_views}</span>
                                        </div>
                                        <div gap={5}>
                                            {auFacebook != "" && 
                                               <a href={auFacebook} target="_blank" rel="noreferrer" style={{background: "#00A8AA", marginRight: "10px", padding: "8px", color: "white", borderRadius: "50%"}} ><FontAwesomeIcon icon={faFacebookF} roundedCircle /></a> 
                                            }
                                            {auIndeed !="" && 
                                            <a href={auIndeed} target="_blank" rel="noreferrer" style={{background: "#00A8AA", marginRight: "10px", padding: "8px", color: "white", borderRadius: "50%"}}><FontAwesomeIcon icon={faXTwitter} roundedCircle  /></a>               
                                            }
                                            {auTwitter !="" && 
                                                <a href={auTwitter} target="_blank" rel="noreferrer" style={{background: "#00A8AA", marginRight: "10px", padding: "8px", color: "white", borderRadius: "50%"}}><FontAwesomeIcon icon={faLinkedinIn} roundedCircle  /></a>
                                            }
                                            {auInstagram !="" && 
                                                <a href={auTwitter} target="_blank" rel="noreferrer" style={{background: "#00A8AA", marginRight: "10px", padding: "8px", color: "white", borderRadius: "50%"}}><FontAwesomeIcon icon={faInstagram} roundedCircle /></a>                                        
                                            }
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        }
                        {/* Ad Image */}
                        <Card style={{ width: '18rem', border: "1px solid #E5E5E5", background: "#F5F5F5", padding: "0px"}} className="mb-3">
                            <Card.Body style={{padding: "0px"}}>
                                <Image src={small_ad_image}  style={{width: "100%"}}  />
                            </Card.Body>
                        </Card>
                        {/* Hot Categories */}
                        <Card style={{border: "1px solid #E5E5E5", background: "#F5F5F5"}} className="mb-3">
                            <Card.Body >
                                <Card.Title className='mb-3'>Hot Categories</Card.Title>
                                
                                <Card className="bg-dark text-white" style={{borderRadius: "21px", marginBottom: "20px"}}>
                                    <Card.Img src={movie_img} alt="Card image" />
                                    <Card.ImgOverlay>
                                        <Card.Title><div onClick={() => onclickOpenurl("https://broadwayoriginals.com/entertainment")} style={{cursor: "pointer"}}>Entertainment</div></Card.Title>
                                    </Card.ImgOverlay>
                                </Card>
                                <Card className="bg-dark text-white" style={{borderRadius: "21px", marginBottom: "20px"}}>
                                    <Card.Img src={korean_img} alt="Card image" />
                                    <Card.ImgOverlay>
                                        <Card.Title><div onClick={() => onclickOpenurl("https://broadwayoriginals.com/life-style")} style={{cursor: "pointer"}}>Life Style</div></Card.Title>
                                    </Card.ImgOverlay>
                                </Card>
                                <Card className="bg-dark text-white" style={{borderRadius: "21px", marginBottom: "20px"}}>
                                    <Card.Img src={tv_series} alt="Card image" />
                                    <Card.ImgOverlay>
                                        <Card.Title><div onClick={() => onclickOpenurl("https://broadwayoriginals.com/technology")} style={{cursor: "pointer"}}>Tech</div></Card.Title>
                                    </Card.ImgOverlay>
                                </Card>
                                <Card className="bg-dark text-white" style={{borderRadius: "21px"}}>
                                    <Card.Img src={lasttest} alt="Card image" />
                                    <Card.ImgOverlay>
                                        <Card.Title><div onClick={() => onclickOpenurl("https://broadwayoriginals.com/videos")} style={{cursor: "pointer"}}>Videos</div></Card.Title>
                                    </Card.ImgOverlay>
                                </Card>
                            </Card.Body>
                        </Card>
                        {/* Trending Block*/}
                        <Card style={{ border: "1px solid #E5E5E5", background: "#F5F5F5"}} className="mb-3">
                            <Card.Body >
                                <Card.Title className='mb-3'>Trending</Card.Title>
                                {trending_list!="" && trending_list.map((item) =>
                                    <Row className='mb-3'>
                                        <Col><Image src={"https://broadwayoriginals.com/"+item.post_img}  style={{width: "100%"}}  /></Col>
                                        <Col>
                                            <h6 style={{fontSize: "14px", cursor: "pointer"}} onClick={() => onclickOpenurl("/"+item.custom_url)}>{item.post_title}</h6>
                                        </Col>
                                    </Row>                                            
                                )} 
                            </Card.Body>
                        </Card>
                        {/* Ad Image */}
                        <Card style={{ border: "1px solid #E5E5E5", background: "#F5F5F5", padding: "0px"}} className="mb-3">
                            <Card.Body style={{padding: "0px"}}>
                                <Image src={large_ads}  style={{width: "100%"}}  />
                            </Card.Body>
                        </Card>
                         {/* Whats new today */}
                         <Card style={{ border: "1px solid #E5E5E5", background: "#F5F5F5"}} className="mb-3">
                            <Card.Body >
                                <Card.Title className='mb-3'>Whats new today</Card.Title>
                                {todaynews_list!="" && todaynews_list.map((item) =>
                                    <Row className='mb-3'>
                                        <Col><Image src={"https://broadwayoriginals.com/"+item.post_img}  style={{width: "100%"}}  /></Col>
                                        <Col><h6 style={{fontSize: "14px", cursor: "pointer"}} onClick={() => onclickOpenurl("/"+item.custom_url)}>{item.post_title}</h6>   </Col>
                                    </Row>                                            
                                )} 
                            </Card.Body>
                        </Card>
                        
                    </Col>
                </Row>
            </Container>
            {pagefooter}
        </>
    );
}
export default PostView;