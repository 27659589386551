import "../css/WhatsNewToday.css";
import whatsnewtodayicon from "../images/whats-new-today.svg";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye} from "@fortawesome/free-regular-svg-icons"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';


//faChevronRight
function WhatsNewToday(todaynews)
{
    const todaynews_list = JSON.parse(todaynews.todaynews);
    const onclickOpenTodayNews = () => {
        window.location = "/today-news";
    }
    return(
        <>
            {todaynews_list!="" && 
                <Container style={{marginBottom: "60px"}}>
                    <Row style={{marginBottom: "10px"}} >
                        <Col xxl={10} xl={10} lg={10} md={10} sm={12} xs={12}>
                            <h4 className="block-header desktop-view-btn"><Image src={whatsnewtodayicon} loading="lazy" className="section-block-img-icon" /> What’s New Today</h4>
                            <h4 className="block-header mobile-view-btn mobile-block-header"><Image src={whatsnewtodayicon} loading="lazy" className="section-block-img-icon" /> What’s New Today</h4>
                        </Col>
                        <Col xxl={2} xl={2} lg={2} md={2} sm={12} xs={12} className="desktop-view-btn" style={{padding: "0px", textAlign: 'end'}}>
                            <Badge bg="default" className="more-text " style={{fontSize: "18px", background: "white !important", color: "#00a8a9", borderRadius: "20px", padding: "10px", cursor: "pointer", paddingLeft: "25px"}} onClick={onclickOpenTodayNews}><span style={{color: "#00a8a9", fontWeight: 200}}>Explore More <FontAwesomeIcon icon={faChevronRight} style={{fontSize: "15px", paddingLeft: "10px"}} /></span></Badge>
                        </Col>
                    </Row>
                <Row style={{marginTop: "0px"}}>
                        <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12} className="mb-2">
                            {todaynews_list.map((item, index) =>  
                                <>
                                    {index == "0" &&
                                        <Card  className="h-100" style={{border: "none", background: "none", borderRadius: "20px"}}>
                                        <Card.Img variant="top" src={"https://broadwayoriginals.com/"+item.post_img} loading="lazy"  className="final-post-img-size" style={{borderRadius: "20px"}} />
                                        <Card.Body style={{padding: "0px", marginTop: "10px" }}>
                                        <Card.Title ><Image src={"https://broadwayoriginals.com/"+item.author.img} roundedCircle className="final-au-img"  loading="lazy" /> <span className="final-au-name" style={{color: "rgb(50 19 19)"}}>{item.author.name}</span></Card.Title>
                                            <Card.Title ><h6  className="card-row-title"><Nav.Link as={NavLink} to={item.custom_url}><span className="final-post-title">{item.post_title}</span></Nav.Link></h6></Card.Title>
                                           
                                        </Card.Body>
                                    </Card> 
                                    }
                                </>
                                        
                            )}
                            
                        </Col>
                        <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12} style={{padding: "0px"}}>
                            {todaynews_list.map((item1, index1) =>  
                            <>
                                {index1 != 0 &&
                            <div className="row-card" style={{marginBottom: "10px", display: "flex", border: "none", background: "none"}}>
                                    <div className="side-block-post-view-left">
                                        <Image src={"https://broadwayoriginals.com/"+item1.post_img}  className="final-post-block-img" loading="lazy" style={{borderRadius: "20px"}} />
                                    </div>
                                    <div className="side-block-post-view-right">
                                        
                                        <Image src={"https://broadwayoriginals.com/"+item1.author.img} roundedCircle loading="lazy" className="final-side-au-img" /> <span className="final-side-au-name" style={{color: "rgb(50 19 19)"}}>{item1.author.name}</span>
                                        <h6  className="side-space-bt-au"><Nav.Link as={NavLink} to={item1.custom_url}><span className="card-side-row-title">{item1.post_title}</span></Nav.Link></h6>
                                    </div>
                                </div> 
                            }
                            </>
                                        
                            )}
                            
                        </Col>
                </Row>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{padding: "0px", textAlign: 'end'}} className="mobile-view-btn">
                        <Badge bg="default" className="more-text mt-4" style={{fontSize: "18px", background: "none", color: "#00a8a9", borderRadius: "20px", padding: "10px", cursor: "pointer", paddingLeft: "25px", width: "100%", border: "1px solid #00a8a9"}} onClick={onclickOpenTodayNews}><span style={{color: "#00a8a9", fontWeight: 200}}><span style={{float: "left"}}>Explore More</span> <FontAwesomeIcon icon={faChevronRight} style={{float: "right"}} /></span></Badge>
                    </Col>
                </Row>
                </Container>
            }
        </>
        
    );
}

export default WhatsNewToday;