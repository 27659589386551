import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../css/View.css";
import "../App.css";
import "../css/animations.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBar from '../Common/Header';
import Footer from '../Common/Footer';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
function Dashboard()
{
    const [data, setData] = useState(null);
    const [navlist, setNavlist] = useState(null);
    const [pagefooter, setPageFooter] = useState(null);
    const [dispalyloginstatus, setDispalyLoginStatus] = useState(false);
    const fetchContactUsPageData = async (session_bdo_id) => {
        try {
          const response = await axios.get('https://broadwayoriginals.com/apis/index.php?dashboard='+session_bdo_id);
          document.title = "Broadway Originals | Dashboard";
          setData(response.data.pagedata);
          setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
          setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);        
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    const session_bdo_id = sessionStorage.getItem('bdo_id');
    const bdo_fullname = sessionStorage.getItem('bdo_fullname');
    useEffect(() => {
        if (session_bdo_id !== "" && session_bdo_id !== undefined && !isNaN(session_bdo_id) && Number(session_bdo_id) > 0) {
            setDispalyLoginStatus(false);
        }else{
            setDispalyLoginStatus(true);
        }
        fetchContactUsPageData(session_bdo_id);
    }, []);
    return(
        <>
            <Container fluid className='top-header'>
            {navlist}  
        </Container>
        <Container>
            <Row className="mb-3">
                <Col className="view-filter-header">
                    <h3 className='app-page-header'>Welcome {bdo_fullname}!</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    {dispalyloginstatus == true && 
                        <>
                            <div className='text-center' style={{fontWeight: "bold", color: "#dc3545"}}>Please log in to view the dashboard.</div>
                        </>
                    }
                </Col>
            </Row>
            <Row>
            {data!= null && data!="" && data.map((item1) =>  
                <Col xxxl={9} xxl={9} xl={9} lg={9} md={9} sm={12} xs={12} xxs={12} className="h-100" style={{padding: "0px"}}>
                    <div className="row-card" style={{marginBottom: "10px", display: "flex", border: "none", background: "none"}}>
                        <div className="side-block-post-view-left">
                            <Image src={"https://broadwayoriginals.com/"+item1.post_img}  className="final-post-block-img" loading="lazy" style={{borderRadius: "20px"}} />
                        </div>
                        <div className="side-block-post-view-right">
                           <Image src={"https://broadwayoriginals.com/"+item1.author.img} roundedCircle loading="lazy" className="final-side-au-img" /> <span className="final-side-au-name" style={{color: "rgb(50 19 19)"}}>{item1.author.name}</span>
                            <h6  className="side-space-bt-au"><Nav.Link as={NavLink} to={item1.custom_url}><span className="card-side-row-title">{item1.post_title}</span></Nav.Link></h6>
                        </div>
                    </div> 
                </Col>
            )}
            </Row>
        </Container>
        {pagefooter}
        </>
    );
}


export default Dashboard;